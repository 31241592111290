import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import BannerImage from '../../components/Page/BannerImage'
import EventsPage from '../../components/Page/EventsPage'
import { showEvents } from '../../utils/dates'
import ComingSoon from '../../components/Page/ComingSoon'

const events = ({data}) => {

	const eventsData = {
		main: data.mainFestivalEvents.nodes,
		childrens: data.childrensFestivalEvents.nodes,
		schools: data.schoolsFestivalEvents.nodes
	}

	const { env } = data.site.siteMetadata.config

	return (
		<Layout>
			<BannerImage image={data.bannerImage.childImageSharp.gatsbyImageData} />
			{showEvents() || env !== 'production'  ? (
				<EventsPage
					eventsData={eventsData}
					festivalType={`main`}
					festivalYear={2024}
					pageHeading={`2024 Boswell Book Festival Events`}
					showAllEventsOption={true}
					showDayFilter={true}
					showEventDate={true}
					showTypeFilter={true}
					useLinksForTypeFilter={true}
				/>
			) : <ComingSoon />}
		</Layout>
	)
}

export const query = graphql`
query {
	mainFestivalEvents: allFutureTicketingEvent(filter: {category: {eq:"main"}, datetime: {gt:"2024-01-01 00:00", lt:"2025-01-01 00:00 "}}, sort:{fields: datetime, order:ASC}) {
		nodes {
			id
			ft_id
			name
			uuid
			datetime
			date:datetime(formatString:"YYYY-MM-DD")
			time:datetime(formatString:"h:mm a")
			description
			eticket_content
			ticket_content
			valid
			img_path
			local_event_img {
				childImageSharp {
					gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
				}
			}
		}
	}
	childrensFestivalEvents: allFutureTicketingEvent(filter: {category: {eq:"childrens"}, datetime: {gt:"2024-01-01 00:00", lt:"2025-01-01 00:00 "}}, sort:{fields: datetime, order:ASC}) {
		nodes {
			id
			ft_id
			name
			uuid
			datetime
			date:datetime(formatString:"YYYY-MM-DD")
			time:datetime(formatString:"h:mm a")
			description
			eticket_content
			ticket_content
			valid
			img_path
			local_event_img {
				childImageSharp {
					gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
				}
			}
		}
	}
	schoolsFestivalEvents: allFutureTicketingEvent(filter: {category: {eq:"schools"}, datetime: {gt:"2024-01-01 00:00", lt:"2025-01-01 00:00 "}}, sort:{fields: datetime, order:ASC}) {
		nodes {
			id
			ft_id
			name
			uuid
			datetime
			date:datetime(formatString:"YYYY-MM-DD")
			time:datetime(formatString:"h:mm a")
			description
			eticket_content
			ticket_content
			valid
			img_path
			local_event_img {
				childImageSharp {
					gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
				}
			}
		}
	}
	bannerImage: file(relativePath: {eq: "general/Bozzy-16-9-001.jpg"}) {
		childImageSharp {
		  gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
		}
	}
	site {
		siteMetadata {
			config {
				env
			}
		}
	}
}
`

export default events
